// TODO: Render registered stylesheets only once, cause StylesProvider may render multiple (AppConfig / Chat preview)

import type { TExample } from '@atlas/uicorn/preview/modules';
import React, { useEffect, useMemo, useState } from 'react';
import { css, ThemeProvider, createGlobalStyle, type RuleSet, useTheme, type ExecutionProps } from 'styled-components';
import createSchemeStyleSheet from '@atlas/tool/box/style/createSchemeStyleSheet';
import { SCREEN_VARIANTS, TScreenVariant } from '@atlas/uicorn/lib/System/useMatchScreenVariant';
import window from '@atlas/tool/box/browser/window';
import useUiConfig from '@atlas/tool/box/hooks/useUiConfig';

/**
 * Add theme variables to the context of application
 *
 * Exported as a `const` to be used by preview.js
 */
export const StylesProvider = React.memo(function StylesProvider(props: TStylesProviderProps): React.ReactElement {
    const [sessionThemeMode, setSessionThemeMode] = useState<'dark' | 'light' | 'auto' | null>(null);

    const themeMode = sessionThemeMode ?? props.theme;

    const forceLightMode = (off?: boolean) => {
        setSessionThemeMode(off === false ? null : 'light');
    };

    const { useOnboardingPadding } = useUiConfig();

    useEffect(() => {
        if (window) {
            window.document.body.dataset.animations = 'no';
            window.document.body.dataset.theme = themeMode;
        }
        const timeoutId = setTimeout(() => {
            if (window) window.document.body.dataset.animations = 'yes';
        });

        return () => clearTimeout(timeoutId);
    }, [themeMode]);

    const [extensions, setExtensions] = useState<TStylesProviderExtension[]>([]);

    useEffect(() => {
        Object.assign(atlasTheme, {
            addExtension: (extension: TStylesProviderExtension) => {
                const newExtension = Object.assign({}, extension);
                setExtensions((extensions) => extensions.concat(newExtension));
                return () => setExtensions((extensions) => extensions.filter((e) => e !== newExtension));
            },
        });
    }, []);

    const theme = useMemo(() => {
        const dark = themeMode === 'dark';
        const reducedExtensions = extensions.reduce(reduceTheme, {});

        return reduceTheme(Object.assign({}, Object.assign(atlasTheme, { dark })), reducedExtensions);
    }, [extensions, themeMode]);

    const [stylesheets, setStylesheets] = useState<TRegisteredStylesheet[]>(() => registeredStylesheets);

    useEffect(() => {
        subscribers.push(setStylesheets);

        return () => {
            const index = subscribers.indexOf(setStylesheets);
            if (index < 0) return;
            subscribers.splice(index, 1);
        };
    }, []);

    return (
        <ThemeProvider theme={{ ...theme, forceLightMode } as unknown as import('styled-components').DefaultTheme}>
            <ResetStyleSheet base={props.baseSize ?? 10} disableElasticScroll={props.disableElasticScroll} />
            <ColorStyleSheet />
            <ShadowStyleSheet />
            <UtilsStyleSheet />
            <AntStyleSheet />
            <LayoutStyleSheet />
            <SDrawerStyleSheet />
            {useOnboardingPadding && <OnboardingStyleSheet />}
            {stylesheets.map((rs, index) => (
                <rs.Stylesheet key={rs.key ?? index} />
            ))}
            {props.children}
        </ThemeProvider>
    );
});

export default StylesProvider;

// #region Helpers

const reduceTheme = (acc: Record<string, any>, themeObj: Record<string, any>) =>
    Object.entries(themeObj).reduce((acc: Record<string, any>, [key, value]: [string, any]) => {
        if (value === null) return acc;
        if (typeof value !== 'object') return Object.assign(acc, { [key]: value });
        return Object.assign(acc, {
            [key]: {
                ...((acc as any)[key] as object),
                ...value,
            },
        });
    }, acc);

const registeredStylesheets: Array<TRegisteredStylesheet> = [];

const subscribers: Array<React.Dispatch<React.SetStateAction<TRegisteredStylesheet[]>>> = [];

export function registerStylesheet(Stylesheet: TStylesheet) {
    const index = registeredStylesheets.findIndex((rs) => rs.Stylesheet === Stylesheet);
    const instance = index < 0 ? { Stylesheet, links: 0 } : registeredStylesheets[index];

    if (index < 0) registeredStylesheets.push(instance);

    instance.links++;

    subscribers.forEach((subscriber) => subscriber([...registeredStylesheets]));

    return () => {
        instance.links--;
        if (instance.links < 1) {
            const index = registeredStylesheets.indexOf(instance);
            if (index < 0) return;
            registeredStylesheets.splice(index, 1);
            subscribers.forEach((subscriber) => subscriber([...registeredStylesheets]));
        }
    };
}

const fontFamily = "'Inter', -apple-system, Helvetica, Ubuntu, Roboto, 'Segoe Ui', Arial, sans-serif";
const monoFontFamily = '"JetBrains Mono", "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace';

export function font(opts?: { size?: number; height?: number; weight?: TFontWeight; family?: string }) {
    return `${opts?.weight ?? 400} ${(opts?.size ?? 13) / 10}rem/${opts?.height ?? 1.3} ${opts?.family ?? fontFamily}`;
}

const overflowTextCss = css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

function overflowText() {
    return overflowTextCss;
}

function lineClampChangesDisplay(lines = 2) {
    return css`
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: ${lines};
        -webkit-box-orient: vertical;
        display: -webkit-box;
    `;
}

// TODO: parent .ant-input-affix-wrapper (multiline with allowClear) doesn't match border roundness, background etc
const inputCss = css`
    --input-border-color: ${(p) => p.theme.color.grey6};
    --input-placeholder-color: ${(p) => p.theme.color.grey8};

    &.ant-input[disabled]:hover {
        border-color: var(--input-border-color);
    }

    &:not(:disabled):not([data-disabled='true']):not(.ant-input-affix-wrapper-disabled):not(.atlas-disabled):not(
            .ant-input-number-disabled
        ):hover {
        --input-border-color: ${(p) => p.theme.color.grey7};
    }

    font: ${(p) => p.theme.font.normal};
    padding: 0.6rem 0.6rem 0.6rem 0.8rem;
    border: 0.1rem solid var(--input-border-color);
    border-radius: ${(p) => p.theme.radius.input};
    background-color: ${(p) => p.theme.color.grey5};
    color: ${(p) => p.theme.color.grey9};
    transition: border-color 0.1s ease, opacity 0.1s ease;

    &,
    textarea,
    .ant-input,
    .ant-input-number-input {
        &::placeholder {
            color: var(--input-placeholder-color);
        }

        &:placeholder-shown {
            text-overflow: ellipsis;
        }
    }

    &.ant-input-textarea-show-count {
        overflow: hidden;

        &::after {
            color: var(--input-placeholder-color);
            margin: 0;
        }
    }

    .ant-input-clear-icon {
        color: inherit;
    }

    .ant-input {
        color: inherit;
    }

    .ant-input-show-count-suffix {
        color: var(--input-placeholder-color);
    }

    &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    &.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
    &.ant-input-number-status-error:not(.ant-input-number-disabled):not(
            .ant-input-number-borderless
        ).ant-input-number:hover {
        background-color: ${(p) => p.theme.color.grey5};
        border-color: ${(p) => p.theme.color.red7};
    }

    &.ant-select {
        .ant-select-selector {
            border: none;
            background: transparent;
            color: inherit;
            padding: 0;
            height: auto;
            font: inherit;

            &::after {
                line-height: inherit;
            }

            .ant-select-selection-search {
                font: inherit;
                left: 0;
                right: 0;
            }

            .ant-select-selection-search-input {
                height: auto;
            }

            .ant-select-selection-item,
            .ant-select-selection-placeholder {
                font: inherit;
                line-height: inherit;
            }

            .ant-select-selection-placeholder {
                color: var(--input-placeholder-color);
            }
        }

        .ant-select-arrow,
        .ant-select-clear {
            color: inherit;
        }

        &.ant-select-focused:not(.ant-select-disabled) {
            .ant-select-selector {
                border-color: none;
                box-shadow: none;
                border: none;
            }
        }
    }

    &:is(
            :not(:disabled):not([data-disabled='true']):not(.ant-input-affix-wrapper-disabled):not(.atlas-disabled):not(
                    .ant-input-number-disabled
                )
        ):hover {
        border-width: 0.1rem;
        border-color: var(--input-border-color);
    }

    &:is(
            .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(
                    .ant-input-affix-wrapper-borderless
                ).ant-input-affix-wrapper,
            .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(
                    .ant-input-affix-wrapper-borderless
                ).ant-input-affix-wrapper:hover
        ) {
        background-color: ${(p) => p.theme.color.grey5};
        border-color: ${(p) => p.theme.color.red7};
    }

    &:is(:not(:disabled):not([data-disabled='true'])):focus,
    &:is(:not(:disabled):not([data-disabled='true'])).ant-select-focused:not(.ant-select-disabled),
    &:is(:not(:disabled):not([data-disabled='true']))[data-focus='true'] {
        border-width: 0.1rem;
        border-color: ${(p) => p.theme.color.primary};
        box-shadow: 0rem 0rem 0rem 0.2rem rgba(0, 0, 255, 0.1);
    }

    &.ant-input-affix-wrapper-disabled,
    &.atlas-disabled,
    &:disabled,
    &[data-disabled='true'],
    &.ant-input-number-disabled {
        // Rewrite AntD styles
        border-color: var(--input-border-color);
        background-color: ${(p) => p.theme.color.grey5};
        color: ${(p) => p.theme.color.grey9};
        box-shadow: none;
        cursor: not-allowed;
        opacity: 0.5;

        input {
            opacity: 1;
        }
    }

    &.ant-input-affix-wrapper {
        .ant-input {
            border: none;
            &:focus {
                box-shadow: none;
            }
        }
    }

    &:is(:focus-visible) {
        outline: none;
    }
`;

const inlineInputCss = css`
    transition: border-color 0.2s ease, color 0.2s ease;
    font: ${(p) => p.theme.font.normal};
    color: ${(p) => p.theme.color.grey9};
    min-width: 1em;
    max-width: 100%;
    cursor: text;

    overflow: auto;
    display: inline-block;
    display: -webkit-box;

    ${(p) => p.theme.patch.noScroll()};

    cursor: text;

    &:where(:not([data-readonly='true'])) {
        border-bottom: 0.1rem solid ${(p) => p.theme.color.grey6};
    }

    &:where(:not([data-disabled='true']):not([data-readonly='true']):not(:focus)) {
        cursor: pointer;
    }

    &:where(:not(:focus)) {
        ${lineClampChangesDisplay(3)}
    }

    &:where([data-disabled='true']) {
        color: ${(p) => p.theme.color.grey7};
    }

    &:where(:not([data-placeholder='']):empty)::before {
        content: attr(data-placeholder);
        color: ${(p) => p.theme.color.grey8};
        user-select: none;
    }

    &:where(:not([data-disabled='true']):not([data-readonly='true']):hover) {
        border-color: ${(p) => p.theme.color.grey7};
    }

    &:focus {
        outline: none;
    }

    &:where(:not([data-disabled='true']):not([data-readonly='true']):focus) {
        border-color: ${(p) => p.theme.color.primary};
    }

    &:where([data-error='true']) {
        color: ${(p) => p.theme.color.red6};
        border-color: ${(p) => p.theme.color.red7};
    }

    br {
        display: none !important;
    }
`;

const noScrollCss = css`
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
`;

function whenDark(styles: RuleSet) {
    return css`
        body[data-theme='auto'] & {
            @media screen and (prefers-color-scheme: dark) {
                ${styles}
            }
        }

        @media screen {
            body[data-theme='dark'] & {
                ${styles}
            }
        }
    `;
}

function whenScreenVariant(variant: TScreenVariant, styles: RuleSet) {
    return css`
        @media (${SCREEN_VARIANTS[variant]}) {
            ${styles}
        }
    `;
}

// Defaults

const [color, ColorStyleSheet] = createSchemeStyleSheet(
    {
        // Brand colors
        primary: ['#0000ff', '#97a6ef'],
        primary1: ['#E1E7FF', '#323A76'],

        // Functional colors
        no: 'transparent',
        grey9: ['#262626', '#e5e5e5'],
        grey90: '#262626',
        grey91: ['#262626', '#313435'],
        grey92: ['#262626', '#ece8db'],
        grey93: ['#262626', '#515253'],
        grey8: ['#747474', '#b3b3b3'],
        grey80: '#747474',
        grey81: ['#747474', '#e5e5e5'],
        grey7: ['#a4a4a4', '#515253'],
        grey71: ['#a4a4a4', '#b3b3b3'],
        grey72: ['#a4a4a4', '#6b5e0a'],
        grey6: ['#ece8db', '#313435'],
        grey60: '#ece8db',
        grey61: ['#ece8db', '#51525380'],
        grey62: ['#ece8db', '#202122'],
        grey63: ['#ece8db', '#463E05'],
        grey5: ['#fbf9f6', '#202122'],
        grey50: '#fbf9f6',
        grey51: ['#fbf9f6', '#313435'],
        grey52: ['#fbf9f6', '#51525380'],
        grey0: ['#ffffff', '#1b1c1d'],
        grey0o80: ['#ffffffcc', '#1b1c1dcc'],
        grey00: '#ffffff',
        grey01: ['#ffffff', '#313435'],
        grey02: ['#ffffff', '#202122'],
        red0: ['#fdd8d8', '#ee2942'],
        red6: ['#f73c3c', '#f68796'],
        red7: ['#e01b1b', '#f68796'],
        green6: '#16d18e',
        green7: '#008000',
        yellow2: '#fdF6d7',
        yellow21: ['#fdF6d7', '#29250A'],
        yellow3: '#fbf6d0',
        yellow4: '#d8d23a',
        yellow5: '#ffa500',
        yellow6: '#b07d23',
        blue9: ['#4c48f3', '#c6ccf3'],
        blue9Light: '#4c48f3',
        blue81: ['#2942ee', '#E5E5E5'],
        blue8: ['#2942ee', '#97a6ef'],
        blue7: ['#526efa', '#97a6ef'],
        blue6: ['#cfd5fc', '#182891'],
        blue5: ['#dddbf7', '#323a76'],
        green3: '#dbf0e3',
        green5: '#e6f7f0',

        palette1: ['#ee2942', '#fbd0d5'],
        palette1Inverse: ['#ffffff', '#ee2942'],
        palette2: ['#ee7329', '#fbe0d0'],
        palette2Inverse: ['#ffffff', '#ee7329'],
        palette3: ['#eed529', '#fbf6d0'],
        palette3Inverse: ['#262626', '#b07D23'],
        palette4: ['#4ccb7b', '#dbf0e3'],
        palette4Inverse: ['#262626', '#0f783c'],
        palette5: ['#29dec5', '#d5f6f1'],
        palette5Inverse: ['#262626', '#159986'],
        palette6: ['#29b5ee', '#d0effb'],
        palette6Inverse: ['#262626', '#0b5877'],
        palette7: ['#2942ee', '#cfd5fc'],
        palette7Inverse: ['#ffffff', '#0b5877'],
        palette8: ['#a429ee', '#ebd0fb'],
        palette8Inverse: ['#ffffff', '#a429ee'],
        palette9: ['#ee29a5', '#f9b7e1'],
        palette9Inverse: ['#ffffff', '#ee29a5'],
        palette10: ['#949494', '#e3e3e8'],
        palette10Inverse: ['#ffffff', '#747474'],

        // Custom colors
        primaryButtonText: '#ffffff', // Doesn't have dark option because it is rendered on bright colors that match with white good
        gradientPrimaryStart: '#2942ee',
        gradientPrimaryEnd: '#a056ff',
        gradientSelectionStart: ['#2942ee1f', '#2942ee80'],
        gradientSelectionEnd: ['#a056ff1f', '#a056ff80'],
        progressBackground: ['#e0e0ff', '#313435'],
        backdropBackground: ['#ffffff66', '#1b1c1d61'],
        infoBannerBackground: '#e0e0ff',
        discord: '#5865F2',

        shadowExtraLow: ['#26262624', '#1b1c1d'],
        shadowLow: ['#26262614', '#1b1c1d'],
        shadowMedium: ['#26262614', '#1b1c1d'],
        shadowHigh: ['#2626262e', '#1b1c1d'],
        shadowExtraHigh: '#0f0f10',
        shadowExtraHigh2: '#00000052',
        shadowSecondaryAction: ['#704ef933', '#1b1c1d'],
        shadowSecondaryActionActive: ['#704ef9e6', '#1b1c1d'],
        shadowComposer: ['#26262612', '#1b1c1d'],
        shadowComposerFocus: '#2942EE66',
        messageCodeBackground: ['#fdf6e3', '#46443e'],
        messageCodeBackgroundLight: '#fdf6e3',
        messageCodeText: ['#657b83', '#f8fdff'],
        messageCodeTextLight: '#657b83',
        messagePreBackground: ['#fdf6e3', '#46443e'],
        messagePreBackgroundLight: '#fdf6e3',
        messagePreText: ['#657b83', '#f8fdff'],
        messagePreTextLight: '#657b83',
        messageQuoteBorder: '#cccccc',
        messageQuoteText: ['#777777', '#aaaaaa'],
        messageQuoteTextLight: '#777777',
        atlasLeafCodeBackground: '#dddddd',
        atlasLeafCodeText: '#ec5b5c',
    },
    'color',
);

const gradient = {
    primary: `linear-gradient(107deg, ${color.gradientPrimaryStart} 0%, ${color.gradientPrimaryEnd} 99%)`,
};

const [shadow, ShadowStyleSheet] = createSchemeStyleSheet(
    {
        no: '0rem 0rem 0rem 0rem transparent',
        extraLow: `0rem 0.2rem 0.4rem 0.2rem ${color.shadowExtraLow}`, // 38/.14
        extraLowDialog: [
            `0rem 0.2rem 0.4rem 0.2rem ${color.shadowExtraLow}`,
            `0rem 0.2rem 8rem 1.9rem ${color.shadowExtraLow}`,
        ],
        low: `0rem 0.4rem 1.4rem 0rem ${color.shadowLow}`, // 38/.08
        lowDialog: [`0rem 0.4rem 1.4rem 0rem ${color.shadowLow}`, `0rem 0.4rem 8rem 1.9rem ${color.shadowLow}`],
        commandBar: [`0rem 0.4rem 1.4rem 0rem ${color.shadowLow}`, `0rem 0.4rem 8rem 4rem ${color.shadowExtraHigh}`],
        medium: `0rem 0.4rem 2.8rem 0.6rem ${color.shadowMedium}`, // 38/.08
        mediumDialog: [
            `0rem 0.4rem 2.8rem 0.6rem ${color.shadowMedium}`,
            `0rem 0.4rem 8rem 4rem ${color.shadowExtraHigh}`,
        ],
        chatWidget: [
            `0rem 0.4rem 2.8rem 0.6rem ${color.shadowMedium}`,
            `0rem 0.4rem 2.8rem 0.6rem ${color.shadowExtraHigh2}`,
        ],
        high: `0rem 0.4rem 4.0rem 0.6rem ${color.shadowHigh}`, // 38/.18
        highDialog: [`0rem 0.4rem 4.0rem 0.6rem ${color.shadowHigh}`, `0rem 0.4rem 8rem 4rem ${color.shadowExtraHigh}`],

        // Custom shadows
        secondaryAction: `0rem 0.3rem 0.6rem -0.1rem ${color.shadowSecondaryAction}`,
        secondaryActionActive: `0rem 0rem 0.1rem 0rem ${color.shadowSecondaryActionActive}`,
        composer: `0rem 0.4rem 0.8rem 0rem ${color.shadowComposer}`,
        composerMediaFocus: `0rem 0rem 0.4rem 0.2rem ${color.shadowComposerFocus}`,
        composerRulerFocus: `0rem 0rem 0.3rem 0rem ${color.shadowComposerFocus}`,
    },
    'shadow',
);

const radius = {
    small: '0.5rem',
    medium: '0.8rem',
    large: '1.3rem',
    input: '0.7rem',
} as const;

const fonts = {
    normal: font({ height: 1.69 }),
    caption: font({ size: 12, height: 1.36 }),
    h1: font({ size: 25, height: 1.28, weight: 600 }),
    h2: font({ size: 19, height: 1.26, weight: 600 }),
    h3: font({ size: 16, height: 1.31, weight: 600 }),
    h4: font({ size: 14, height: 1.71 }),
    h5: font({ height: 1.54, weight: 550 }),
    input: font({ height: 1, weight: 400 }),
    tooltip: font({ size: 12, height: 1.5 }),
    code: font({ size: 16, height: 1.31, weight: 500, family: monoFontFamily }),
};

const patch = {
    input: () => inputCss,
    inlineInput: () => inlineInputCss,
    noScroll: () => noScrollCss,
    lineClampChangesDisplay,
} as const;

const ResetStyleSheet = createGlobalStyle<{ base: number; disableElasticScroll?: boolean }>`
  :root {
    font-size: ${(p) => p.base}px;
  }

  body {
    background-color: ${(p) => p.theme.color.grey0};
    color: ${(p) => p.theme.color.grey9};
    ${(p) =>
        p.disableElasticScroll &&
        css`
            overscroll-behavior: none;
        `};
  }
`;

const layoutVariables = {
    headerHeight: '--layout-header-height',
    pageOffset: '--layout-page-offset',
    necessaryBottomOffset: '--layout-necessary-bottom-offset',
    onboardingPageBottomPadding: '--layout-onboarding-page-bottom-padding',
} as const;

export const layout = {
    headerHeight: `var(${layoutVariables.headerHeight})`,
    pageOffset: `var(${layoutVariables.pageOffset})`,
    necessaryBottomOffset: `var(${layoutVariables.necessaryBottomOffset})`,
    onboardingPageBottomPadding: `var(${layoutVariables.onboardingPageBottomPadding})`,
} as const;

const OnboardingStyleSheet = createGlobalStyle`
    :root {
        ${layoutVariables.onboardingPageBottomPadding}: 6rem;
    }
`;

const UtilsStyleSheet = createGlobalStyle`
    body[data-animations='no'] * {
        animation-play-state: paused !important;
        transition: none !important;
    }
`;

const AntStyleSheet = createGlobalStyle`
    .ant-select-dropdown.ant-select-dropdown-empty {
        .ant-empty {
            font: ${(p) => p.theme.font.normal};
        }

        .ant-empty-normal {
            color: ${(p) => p.theme.color.grey7};
        }

        .ant-empty-img-simple-ellipse,
        .ant-empty-img-simple-path {
            fill: ${(p) => p.theme.color.grey5};
        }

        .ant-empty-img-simple-g,
        .ant-empty-img-simple-path {
            stroke: ${(p) => p.theme.color.grey6};
        }
    }

    .ant-dropdown-menu-item.ant-dropdown-menu-item-disabled,
    .ant-dropdown-menu-item.ant-dropdown-menu-item-disabled:hover {
        color: ${(p) => p.theme.color.grey9};
        opacity: 0.25;
    }
`;

const LayoutStyleSheet = createGlobalStyle`
    :root {
        ${layoutVariables.headerHeight}: 5.6rem;
        @media (max-height: 630px) {
            ${layoutVariables.headerHeight}: 4.8rem;
        }

        ${layoutVariables.pageOffset}: 1.6rem;
        ${layoutVariables.necessaryBottomOffset}: 6.0rem;
    }
`;

const SDrawerStyleSheet = createGlobalStyle`
    .ant-drawer-header {
        background: ${(p) => p.theme.color.grey5};
        height: 0;
        padding: 0;
        border-color: ${(p) => p.theme.color.grey6};
    }

    .ant-drawer-close {
        float: right;
        margin-top: 60px;
        position: relative;
        z-index: 1;
        color: ${(p) => p.theme.color.grey8};
    }

    .ant-drawer-header-title {
        display: block;
    }

    .ant-drawer-content-wrapper {
        max-width: 100vw;
    }

    &[data-desktop="true"],
    .ant-drawer-body {
        background-color: ${(p) => p.theme.color.grey5};
        border-left: 0.1rem solid ${(p) => p.theme.color.grey6};
        height: 100%;
        padding: 1.6rem 2rem ${(p) => p.theme.layout.necessaryBottomOffset};
        overflow-y: auto;
        overflow-x: hidden;
    }
`;

export const atlasTheme = {
    color,
    gradient,
    font: Object.assign(font, fonts),
    shadow,
    radius,
    layout,
    patch,
    overflowText,
    when: { dark: whenDark, screenVariant: whenScreenVariant },
    addExtension: (_extension: TStylesProviderExtension) => () => {},
} as const;

// #endregion

// #region Types

type TFontWeight = 100 | 200 | 300 | 400 | 500 | 550 | 600 | 700 | 800;

export type TAtlasTheme = typeof atlasTheme;

export type TThemeColor = keyof TAtlasTheme['color'];

export type TStylesheet = React.NamedExoticComponent<ExecutionProps & { theme?: TAtlasTheme; key?: React.Key }>;

type TRegisteredStylesheet = {
    Stylesheet: TStylesheet;
    links: number;
    key?: React.Key;
};

export type TStylesProviderExtension = {
    color?: Record<string, string>;
    gradient?: Record<string, string>;
    font?: Record<string, string>;
    shadow?: Record<string, string>;
    [key: string]: unknown | Record<string, unknown>;
};

export type TStylesProviderProps = {
    children: React.ReactNode;
    baseSize?: number;
    theme?: 'light' | 'dark' | 'auto';
    disableElasticScroll?: boolean;
};

declare module 'styled-components' {
    export interface DefaultTheme extends TAtlasTheme {}
}

// #endregion

// #region Examples

export const Example: TExample = {
    layout: 'column',
    render() {
        function Child() {
            const theme = useTheme();

            return (
                <>
                    <strong>Colors</strong>
                    <div style={{ display: 'grid', gridTemplate: 'auto / repeat(3, 1fr)', gap: 10 }}>
                        {Object.entries(theme.color).map(([name, value]) => (
                            <span key={name} style={{ backgroundColor: value, padding: '15px' }}>
                                theme.color.{name}
                            </span>
                        ))}
                    </div>

                    <strong>Gradients</strong>
                    <div style={{ display: 'grid', gridTemplate: 'auto / repeat(1, 1fr)', gap: 10 }}>
                        {Object.entries(theme.gradient).map(([name, value]) => (
                            <span key={name} style={{ backgroundImage: value, padding: '15px' }}>
                                theme.gradient.{name}
                            </span>
                        ))}
                    </div>

                    <strong>Fonts</strong>
                    <div style={{ display: 'grid', gridTemplate: 'auto / repeat(3, 1fr)', gap: 10 }}>
                        {Object.entries(theme.font).map(([name, value]) => (
                            <span key={name} style={{ font: value, padding: '15px' }}>
                                theme.font.{name}
                            </span>
                        ))}
                    </div>

                    <strong>Shadows</strong>
                    <div style={{ display: 'grid', gridTemplate: 'auto / repeat(3, 1fr)', gap: 10 }}>
                        {Object.entries(theme.shadow).map(([name, value]) => (
                            <span
                                key={name}
                                style={{ background: '#fff', color: '#262626', boxShadow: value, padding: '15px' }}
                            >
                                theme.shadow.{name}
                            </span>
                        ))}
                    </div>

                    <strong>Radiuses</strong>
                    <div style={{ display: 'grid', gridTemplate: 'auto / repeat(2, 1fr)', gap: 10 }}>
                        {Object.entries(theme.radius).map(([name, value]) => (
                            <span
                                key={name}
                                style={{
                                    border: '1px solid red',
                                    background: '#fff',
                                    color: '#262626',
                                    borderRadius: value,
                                    padding: '15px',
                                }}
                            >
                                theme.radius.{name}
                            </span>
                        ))}
                    </div>
                </>
            );
        }

        return (
            <StylesProvider>
                <Child />
            </StylesProvider>
        );
    },
};

// #endregion

// #region Tests

if (import.meta.vitest) {
    const { describe, test, expect, beforeAll, vi } = import.meta.vitest;
    describe('ErrorBoundary', async () => {
        const testModule = '@test';
        const { render } = await import(/* @vite-ignore */ testModule);

        beforeAll(function mockAddEventListener() {
            vi.mock('@atlas/tool/box/browser/localStorage', () => {
                return {
                    default: {
                        read: () => 'light',
                        has: () => true,
                        subscribe: () => {},
                    },
                };
            });

            vi.mock('@atlas/tool/box/browser/window', () => {
                return {
                    default: {
                        document: {
                            body: {
                                dataset: {},
                            },
                        },
                        location: {
                            search: '',
                        },
                    },
                };
            });

            vi.mock('@atlas/tool/box/hooks/useUiConfig', () => {
                return {
                    default: () => ({ useOnboardingPadding: false }),
                };
            });
        });

        test('matches snapshot', async () => {
            const result = render(<Example.render />);
            expect(result.container).toMatchSnapshot();
        });
    });
}

// #endregion
